import React, { createContext, useContext, useState, useCallback } from 'react';

interface NumberEntry {
  id: string;
  number: string;
  title?: string;
  source?: string;
  timestamp: Date;
}

interface NumbersContextType {
  numbers: NumberEntry[];
  addNumbers: (newNumbers: Array<{ number: string; title?: string }>, source?: string) => void;
  removeNumber: (id: string) => void;
  clearNumbers: () => void;
  saveToFile: () => void;
}

const NumbersContext = createContext<NumbersContextType | undefined>(undefined);

export function NumbersProvider({ children }: { children: React.ReactNode }) {
  const [numbers, setNumbers] = useState<NumberEntry[]>([]);

  const addNumbers = useCallback((newNumbers: Array<{ number: string; title?: string }>, source?: string) => {
    const newEntries = newNumbers.map(({ number, title }) => ({
      id: Math.random().toString(36).substr(2, 9),
      number,
      title,
      source,
      timestamp: new Date()
    }));
    setNumbers(prev => [...prev, ...newEntries]);
  }, []);

  const removeNumber = useCallback((id: string) => {
    setNumbers(prev => prev.filter(n => n.id !== id));
  }, []);

  const clearNumbers = useCallback(() => {
    setNumbers([]);
  }, []);

  const saveToFile = useCallback(() => {
    const content = numbers
      .map(n => `${n.number}${n.title ? ` - ${n.title}` : ''}`)
      .join('\n');
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'numeros-whatsapp.txt';
    a.click();
    URL.revokeObjectURL(url);
  }, [numbers]);

  return (
    <NumbersContext.Provider value={{ numbers, addNumbers, removeNumber, clearNumbers, saveToFile }}>
      {children}
    </NumbersContext.Provider>
  );
}

export function useNumbers() {
  const context = useContext(NumbersContext);
  if (context === undefined) {
    throw new Error('useNumbers must be used within a NumbersProvider');
  }
  return context;
}