import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from './utils/errorBoundary';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingSpinner } from './components/ui/LoadingSpinner';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { NumbersProvider } from './contexts/NumbersContext';
import { SearchSettingsProvider } from './contexts/SearchSettingsContext';
import { MessageLogProvider } from './contexts/MessageLogContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { MessageTrackingProvider } from './contexts/MessageTrackingContext';
import { PaymentSettingsProvider } from './contexts/PaymentSettingsContext';

// Lazy load pages
const LandingPage = React.lazy(() => import('./pages/LandingPage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const RegisterPage = React.lazy(() => import('./pages/RegisterPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const MessagesPage = React.lazy(() => import('./pages/MessagesPage'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const AdminDashboard = React.lazy(() => import('./pages/admin/AdminDashboard'));
const AdminSettingsPage = React.lazy(() => import('./pages/admin/AdminSettingsPage'));
const AdminProfilePage = React.lazy(() => import('./pages/admin/AdminProfilePage'));
const PaymentsPage = React.lazy(() => import('./pages/admin/PaymentsPage'));
const ReportsPage = React.lazy(() => import('./pages/admin/ReportsPage'));
const UsersPage = React.lazy(() => import('./pages/admin/UsersPage'));
const NotificationsPage = React.lazy(() => import('./pages/admin/NotificationsPage'));
const UpgradePage = React.lazy(() => import('./pages/UpgradePage'));

const queryClient = new QueryClient();

export default function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <PaymentSettingsProvider>
            <NumbersProvider>
              <SearchSettingsProvider>
                <MessageLogProvider>
                  <NotificationProvider>
                    <MessageTrackingProvider>
                      <Suspense fallback={<LoadingSpinner />}>
                        <Routes>
                          <Route path="/" element={<LandingPage />} />
                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/register" element={<RegisterPage />} />
                          <Route path="/upgrade" element={<UpgradePage />} />
                          
                          <Route path="/dashboard" element={
                            <ProtectedRoute>
                              <DashboardPage />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/messages" element={
                            <ProtectedRoute>
                              <MessagesPage />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/settings" element={
                            <ProtectedRoute>
                              <SettingsPage />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/admin" element={
                            <ProtectedRoute requireAdmin>
                              <AdminDashboard />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/admin/profile" element={
                            <ProtectedRoute requireAdmin>
                              <AdminProfilePage />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/admin/settings" element={
                            <ProtectedRoute requireAdmin>
                              <AdminSettingsPage />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/admin/payments" element={
                            <ProtectedRoute requireAdmin>
                              <PaymentsPage />
                            </ProtectedRoute>
                          } />
                          
                          <Route path="/admin/reports" element={
                            <ProtectedRoute requireAdmin>
                              <ReportsPage />
                            </ProtectedRoute>
                          } />

                          <Route path="/admin/users" element={
                            <ProtectedRoute requireAdmin>
                              <UsersPage />
                            </ProtectedRoute>
                          } />

                          <Route path="/admin/notifications" element={
                            <ProtectedRoute requireAdmin>
                              <NotificationsPage />
                            </ProtectedRoute>
                          } />

                          <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                      </Suspense>
                    </MessageTrackingProvider>
                  </NotificationProvider>
                </MessageLogProvider>
              </SearchSettingsProvider>
            </NumbersProvider>
          </PaymentSettingsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}