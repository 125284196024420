import React, { createContext, useContext, useState, useEffect } from 'react';

interface MessageStats {
  totalSent: number;
  dailySent: number;
  lastSent: Date | null;
}

interface MessageTrackingContextType {
  stats: MessageStats;
  incrementSentCount: () => void;
  resetDailyCount: () => void;
}

const MessageTrackingContext = createContext<MessageTrackingContextType | undefined>(undefined);

export function MessageTrackingProvider({ children }: { children: React.ReactNode }) {
  const [stats, setStats] = useState<MessageStats>(() => {
    const saved = localStorage.getItem('messageStats');
    if (saved) {
      const parsed = JSON.parse(saved);
      return {
        ...parsed,
        lastSent: parsed.lastSent ? new Date(parsed.lastSent) : null
      };
    }
    return {
      totalSent: 0,
      dailySent: 0,
      lastSent: null
    };
  });

  useEffect(() => {
    localStorage.setItem('messageStats', JSON.stringify(stats));
  }, [stats]);

  // Reset daily count at midnight
  useEffect(() => {
    const checkDate = () => {
      if (stats.lastSent) {
        const lastSentDate = new Date(stats.lastSent);
        const today = new Date();
        if (lastSentDate.getDate() !== today.getDate()) {
          resetDailyCount();
        }
      }
    };

    const interval = setInterval(checkDate, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [stats.lastSent]);

  const incrementSentCount = () => {
    setStats(prev => ({
      totalSent: prev.totalSent + 1,
      dailySent: prev.dailySent + 1,
      lastSent: new Date()
    }));
  };

  const resetDailyCount = () => {
    setStats(prev => ({
      ...prev,
      dailySent: 0
    }));
  };

  return (
    <MessageTrackingContext.Provider value={{ stats, incrementSentCount, resetDailyCount }}>
      {children}
    </MessageTrackingContext.Provider>
  );
}

export function useMessageTracking() {
  const context = useContext(MessageTrackingContext);
  if (!context) {
    throw new Error('useMessageTracking must be used within MessageTrackingProvider');
  }
  return context;
}