import React, { createContext, useContext, useState, useEffect } from 'react';
import { addDays, differenceInDays } from 'date-fns';
import { sendEmail } from '../utils/email';

export interface User {
  id: string;
  email: string;
  name: string;
  isAdmin: boolean;
  trialEndsAt?: Date;
  subscriptionEndsAt?: Date;
  trialDays?: number;
  status: 'active' | 'pending' | 'blocked';
  plan: 'trial' | 'professional';
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string, name: string) => Promise<void>;
  updateProfile: (updates: Partial<User>) => Promise<void>;
  updatePassword: (currentPassword: string, newPassword: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const ADMIN_CREDENTIALS = {
  email: 'humberto@vidrolimpo.com',
  password: '323232HHH@',
  name: 'Administrador'
};

// Get trial days from admin settings or default to 1
const getTrialDays = () => {
  const settings = localStorage.getItem('systemSettings');
  if (settings) {
    const parsed = JSON.parse(settings);
    return parsed.trialPeriodDays || 1;
  }
  return 1;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      if (parsedUser.trialEndsAt) {
        parsedUser.trialEndsAt = new Date(parsedUser.trialEndsAt);
      }
      if (parsedUser.subscriptionEndsAt) {
        parsedUser.subscriptionEndsAt = new Date(parsedUser.subscriptionEndsAt);
      }
      setUser(parsedUser);
    }
    setLoading(false);
  }, []);

  // Check trial/subscription status daily
  useEffect(() => {
    if (!user) return;

    const checkStatus = async () => {
      if (user.trialEndsAt) {
        const daysLeft = differenceInDays(user.trialEndsAt, new Date());
        
        if (daysLeft <= 3 && daysLeft > 0) {
          // Send trial ending soon email
          await sendEmail(user.email, 'trialEnding', {
            ...user,
            trialDays: daysLeft
          });
        } else if (daysLeft <= 0) {
          // Send trial expired email
          await sendEmail(user.email, 'trialExpired', user);
        }
      }
    };

    const interval = setInterval(checkStatus, 24 * 60 * 60 * 1000); // Check daily
    checkStatus(); // Check immediately

    return () => clearInterval(interval);
  }, [user]);

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      // Check if admin login
      if (email === ADMIN_CREDENTIALS.email) {
        if (password !== ADMIN_CREDENTIALS.password) {
          throw new Error('INVALID_PASSWORD');
        }

        const adminUser: User = {
          id: 'admin',
          email: ADMIN_CREDENTIALS.email,
          name: ADMIN_CREDENTIALS.name,
          isAdmin: true,
          status: 'active',
          plan: 'professional'
        };

        setUser(adminUser);
        localStorage.setItem('user', JSON.stringify(adminUser));
        return;
      }

      // Regular user login logic
      const storedUsers = JSON.parse(localStorage.getItem('users') || '[]');
      const foundUser = storedUsers.find((u: any) => u.email === email);

      if (!foundUser) {
        throw new Error('USER_NOT_FOUND');
      }

      if (foundUser.password !== password) {
        throw new Error('INVALID_PASSWORD');
      }

      const userData: User = {
        id: foundUser.id,
        email: foundUser.email,
        name: foundUser.name,
        isAdmin: false,
        status: foundUser.status || 'active',
        plan: foundUser.plan || 'trial',
        trialEndsAt: foundUser.trialEndsAt ? new Date(foundUser.trialEndsAt) : undefined,
        subscriptionEndsAt: foundUser.subscriptionEndsAt ? new Date(foundUser.subscriptionEndsAt) : undefined
      };

      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } finally {
      setLoading(false);
    }
  };

  const register = async (email: string, password: string, name: string) => {
    setLoading(true);
    try {
      // Check if email already exists
      const storedUsers = JSON.parse(localStorage.getItem('users') || '[]');
      if (storedUsers.some((u: any) => u.email === email)) {
        throw new Error('EMAIL_EXISTS');
      }

      const trialDays = getTrialDays();
      const trialEndsAt = addDays(new Date(), trialDays);

      const newUser = {
        id: Math.random().toString(36).substr(2, 9),
        email,
        password,
        name,
        status: 'active',
        plan: 'trial',
        trialEndsAt: trialEndsAt.toISOString(),
        trialDays
      };

      // Save to localStorage
      localStorage.setItem('users', JSON.stringify([...storedUsers, newUser]));

      // Create session
      const userData: User = {
        id: newUser.id,
        email: newUser.email,
        name: newUser.name,
        isAdmin: false,
        status: 'active',
        plan: 'trial',
        trialEndsAt,
        trialDays
      };

      // Send welcome email
      await sendEmail(email, 'welcome', userData);

      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const updateProfile = async (updates: Partial<User>) => {
    if (!user) throw new Error('No user logged in');

    const updatedUser = { ...user, ...updates };
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));

    if (user.isAdmin) {
      // Update admin credentials in memory only
      ADMIN_CREDENTIALS.name = updates.name || ADMIN_CREDENTIALS.name;
      ADMIN_CREDENTIALS.email = updates.email || ADMIN_CREDENTIALS.email;
    } else {
      // Update regular user in localStorage
      const storedUsers = JSON.parse(localStorage.getItem('users') || '[]');
      const updatedUsers = storedUsers.map((u: any) =>
        u.id === user.id ? { ...u, ...updates } : u
      );
      localStorage.setItem('users', JSON.stringify(updatedUsers));
    }
  };

  const updatePassword = async (currentPassword: string, newPassword: string) => {
    if (!user) throw new Error('No user logged in');

    if (user.isAdmin) {
      if (currentPassword !== ADMIN_CREDENTIALS.password) {
        throw new Error('INVALID_PASSWORD');
      }
      ADMIN_CREDENTIALS.password = newPassword;
    } else {
      const storedUsers = JSON.parse(localStorage.getItem('users') || '[]');
      const userIndex = storedUsers.findIndex((u: any) => u.id === user.id);
      
      if (userIndex === -1 || storedUsers[userIndex].password !== currentPassword) {
        throw new Error('INVALID_PASSWORD');
      }

      storedUsers[userIndex].password = newPassword;
      localStorage.setItem('users', JSON.stringify(storedUsers));
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      loading, 
      login, 
      logout, 
      register, 
      updateProfile,
      updatePassword 
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}