import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAdmin?: boolean;
}

export function ProtectedRoute({ children, requireAdmin = false }: ProtectedRouteProps) {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requireAdmin && !user.isAdmin) {
    // If admin access is required but user is not admin, redirect to dashboard
    return <Navigate to="/dashboard" replace />;
  }

  if (!requireAdmin && user.isAdmin && location.pathname === '/dashboard') {
    // If user is admin and trying to access regular dashboard, redirect to admin dashboard
    return <Navigate to="/admin" replace />;
  }

  return <>{children}</>;
}