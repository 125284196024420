import React, { createContext, useContext, useState, useCallback } from 'react';
import { NotificationItem, NotificationFormData } from '../types/notifications';

interface NotificationContextType {
  notifications: NotificationItem[];
  addNotification: (data: NotificationFormData) => Promise<void>;
  updateNotification: (id: string, updates: Partial<NotificationItem>) => void;
  deleteNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const addNotification = useCallback(async (data: NotificationFormData) => {
    const newNotification: NotificationItem = {
      id: Math.random().toString(36).substr(2, 9),
      ...data,
      status: 'pending',
      recipients: Math.floor(Math.random() * 100) + 50,
      createdAt: new Date()
    };

    setNotifications(prev => [newNotification, ...prev]);
  }, []);

  const updateNotification = useCallback((id: string, updates: Partial<NotificationItem>) => {
    setNotifications(prev => prev.map(notification => 
      notification.id === id ? { ...notification, ...updates } : notification
    ));
  }, []);

  const deleteNotification = useCallback((id: string) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);

  return (
    <NotificationContext.Provider value={{
      notifications,
      addNotification,
      updateNotification,
      deleteNotification
    }}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within NotificationProvider');
  }
  return context;
}