import React, { createContext, useContext, useState } from 'react';

interface SearchSettings {
  humanBehavior: {
    enabled: boolean;
    mouseMovement: boolean;
    randomClicks: boolean;
    pageScrolling: boolean;
    delayBetweenActions: number;
    pagesPerSearch: number;
  };
  searchLimits: {
    maxResults: number;
    delayBetweenSearches: number;
    maxSearchesPerHour: number;
  };
}

interface SearchSettingsContextType {
  settings: SearchSettings;
  updateSettings: (newSettings: Partial<SearchSettings>) => void;
}

const defaultSettings: SearchSettings = {
  humanBehavior: {
    enabled: true,
    mouseMovement: true,
    randomClicks: true,
    pageScrolling: true,
    delayBetweenActions: 2000,
    pagesPerSearch: 3,
  },
  searchLimits: {
    maxResults: 50,
    delayBetweenSearches: 30000,
    maxSearchesPerHour: 10,
  },
};

const SearchSettingsContext = createContext<SearchSettingsContextType | undefined>(undefined);

export function SearchSettingsProvider({ children }: { children: React.ReactNode }) {
  const [settings, setSettings] = useState<SearchSettings>(defaultSettings);

  const updateSettings = (newSettings: Partial<SearchSettings>) => {
    setSettings(prev => ({
      ...prev,
      ...newSettings,
      searchLimits: {
        ...prev.searchLimits,
        ...(newSettings.searchLimits || {}),
      },
    }));
  };

  return (
    <SearchSettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SearchSettingsContext.Provider>
  );
}

export function useSearchSettings() {
  const context = useContext(SearchSettingsContext);
  if (!context) {
    throw new Error('useSearchSettings must be used within SearchSettingsProvider');
  }
  return context;
}