import { User } from '../contexts/AuthContext';
import { APP_CONFIG } from './constants';

export async function sendEmail(to: string, templateName: string, data: User) {
  // Mock email sending for frontend demo
  const templates = {
    welcome: {
      subject: `Bem-vindo ao ${APP_CONFIG.companyName}!`,
      body: `Olá ${data.name},

Seja bem-vindo ao ${APP_CONFIG.companyName}! Estamos muito felizes em ter você conosco.

Seu período de teste gratuito de ${data.trialDays} dias começou hoje.

Durante este período você terá acesso a todos os recursos premium:
- Extração ilimitada de números
- Sistema anti-bloqueio avançado
- Envio automático de mensagens
- Suporte prioritário

Para começar:
1. Acesse seu painel: https://${APP_CONFIG.domain}/dashboard
2. Configure seu perfil
3. Comece a extrair números

Se precisar de ajuda, entre em contato:
WhatsApp: ${APP_CONFIG.supportPhone}
Email: ${APP_CONFIG.supportEmail}

Abraços,
Equipe ${APP_CONFIG.companyName}`
    }
  };

  const template = templates[templateName as keyof typeof templates];
  
  console.log('Sending email:', {
    to,
    subject: template.subject,
    body: template.body
  });

  // In a real implementation, this would call your backend API
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      console.log('Email sent successfully');
      resolve();
    }, 1000);
  });
}