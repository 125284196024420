import React, { createContext, useContext, useState, useEffect } from 'react';

interface PaymentSettings {
  pixKey: string;
  planPrice: number;
  paymentEmail: string;
}

interface PaymentSettingsContextType {
  settings: PaymentSettings;
  updateSettings: (newSettings: Partial<PaymentSettings>) => void;
}

const PaymentSettingsContext = createContext<PaymentSettingsContextType | undefined>(undefined);

const defaultSettings: PaymentSettings = {
  pixKey: '547.568.981-49',
  planPrice: 197.00,
  paymentEmail: 'comprovante@whatsappextrator.com'
};

export function PaymentSettingsProvider({ children }: { children: React.ReactNode }) {
  const [settings, setSettings] = useState<PaymentSettings>(() => {
    const saved = localStorage.getItem('paymentSettings');
    return saved ? JSON.parse(saved) : defaultSettings;
  });

  useEffect(() => {
    localStorage.setItem('paymentSettings', JSON.stringify(settings));
  }, [settings]);

  const updateSettings = (newSettings: Partial<PaymentSettings>) => {
    setSettings(prev => ({
      ...prev,
      ...newSettings
    }));
  };

  return (
    <PaymentSettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </PaymentSettingsContext.Provider>
  );
}

export function usePaymentSettings() {
  const context = useContext(PaymentSettingsContext);
  if (!context) {
    throw new Error('usePaymentSettings must be used within PaymentSettingsProvider');
  }
  return context;
}