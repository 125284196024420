import React, { createContext, useContext, useState } from 'react';

export type LogStatus = 
  | 'disconnected'
  | 'connecting'
  | 'connected'
  | 'sending'
  | 'sent'
  | 'failed'
  | 'api_error';

export interface MessageLog {
  id: string;
  number: string;
  status: LogStatus;
  timestamp: Date;
  error?: string;
}

interface MessageLogContextType {
  logs: MessageLog[];
  addLog: (log: Omit<MessageLog, 'id' | 'timestamp'>) => void;
  updateLog: (id: string, updates: Partial<MessageLog>) => void;
  clearLogs: () => void;
}

const MessageLogContext = createContext<MessageLogContextType | undefined>(undefined);

export function MessageLogProvider({ children }: { children: React.ReactNode }) {
  const [logs, setLogs] = useState<MessageLog[]>([]);

  const addLog = (log: Omit<MessageLog, 'id' | 'timestamp'>) => {
    const newLog = {
      ...log,
      id: Math.random().toString(36).substr(2, 9),
      timestamp: new Date(),
    };
    setLogs(prev => [newLog, ...prev]);
  };

  const updateLog = (id: string, updates: Partial<MessageLog>) => {
    setLogs(prev => prev.map(log => 
      log.id === id ? { ...log, ...updates } : log
    ));
  };

  const clearLogs = () => {
    setLogs([]);
  };

  return (
    <MessageLogContext.Provider value={{ logs, addLog, updateLog, clearLogs }}>
      {children}
    </MessageLogContext.Provider>
  );
}

export function useMessageLog() {
  const context = useContext(MessageLogContext);
  if (!context) {
    throw new Error('useMessageLog must be used within MessageLogProvider');
  }
  return context;
}