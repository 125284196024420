export const APP_CONFIG = {
  domain: 'numerowhatsapp.com',
  supportPhone: '5511999999999',
  supportEmail: 'suporte@numerowhatsapp.com',
  paymentEmail: 'comprovante@numerowhatsapp.com',
  companyName: 'WhatsApp Extrator',
  displayName: 'WhatsApp Extrator',
  subDomain: 'NumeroWhatsApp.com',
  social: {
    whatsapp: 'https://wa.me/5511999999999',
    instagram: 'https://instagram.com/numerowhatsapp',
    facebook: 'https://facebook.com/numerowhatsapp'
  }
};

export const TRIAL_CONFIG = {
  defaultDays: 1,
  warningDays: 3,
  features: [
    'Extração ilimitada de números',
    'Sistema anti-bloqueio avançado',
    'Envio automático de mensagens',
    'Suporte prioritário'
  ]
};

export const PLAN_PRICES = {
  monthly: 197.00,
  quarterly: 527.00,
  yearly: 1897.00
};